<template>
  <v-container v-if="user" class="pa-0">
    <v-row justify="center">
      <v-col cols="12" sm="9">
        <v-row no-gutters class="mb-4">
          <v-col cols="12" sm="4">
            <h2>{{ $t('PageTitles.UserDetails') }}</h2>
          </v-col>

          <v-col cols="12" md="4" class="text-end">
            <v-btn
              :block="$vuetify.breakpoint.mobile"
              :class="$vuetify.breakpoint.mobile ? 'my-4' : 'mr-4'"
              @click="$refs.ChangePasswordRef.changePassword(user, true)"
              :title="$t('UserListHeaders.SetUserPassword')"
            >
              <v-icon left>mdi-lock-reset</v-icon>
              {{ $t('UserListHeaders.SetUserPassword') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              :block="$vuetify.breakpoint.mobile"
              :class="$vuetify.breakpoint.mobile ? 'mb-4' : ''"
              @click="sendPasswordChangeEmail"
              :title="$t('UserListHeaders.SendPasswordResetEmail')"
            >
              <v-icon left>mdi-email-send</v-icon>
              {{ $t('UserListHeaders.SendPasswordResetEmail') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-simple-table dense class="noHoverEffect pa-2">
          <tbody>
            <tr>
              <td class="font-weight-bold" style="width: 200px">{{ $t('UserListHeaders.Name') }}</td>
              <td>{{ user.name }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="width: ">{{ $t('UserListHeaders.CompanyName') }}</td>
              <td v-if="isAdmin">
                <router-link :to="{ name: 'CompanyDetails', params: { Id: user.companyId } }">{{ user.companyName }}</router-link>
              </td>
              <td v-else>
                <router-link :to="{ name: 'MyCompany' }">{{ user.companyName }}</router-link>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold" style="width: ">{{ $t('UserListHeaders.PhoneNumber') }}</td>
              <td>{{ user.phoneNumber }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('UserListHeaders.Email') }}</td>
              <td>{{ user.email }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('UserDetailsListHeaders.State') }}</td>
              <td>{{ $util.formatDisabledState(this.user.disabled) }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('UserDetailsListHeaders.Roles') }}</td>
              <td>{{ roleString }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-card-actions class="mt-2 pa-0">
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-btn
                :block="$vuetify.breakpoint.mobile"
                :class="$vuetify.breakpoint.mobile ? 'mb-2' : ''"
                :loading="isUpdating"
                @click="changeState"
                v-if="!user.disabled && isAdmin"
                color="error"
              >
                <v-icon left>mdi-cancel</v-icon>
                {{ $t('ButtonsLabel.Disable') }}
              </v-btn>
              <v-btn
                :block="$vuetify.breakpoint.mobile"
                :class="$vuetify.breakpoint.mobile ? 'mb-2' : ''"
                :loading="isUpdating"
                @click="changeState"
                v-if="user.disabled && isAdmin"
                color="success"
              >
                <v-icon left>mdi-account-reactivate</v-icon>
                {{ $t('ButtonsLabel.Activate') }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" align="right">
              <v-btn @click="EditUser" :block="$vuetify.breakpoint.mobile" :class="$vuetify.breakpoint.mobile ? 'mb-4' : ''">
                <v-icon left>mdi-pencil</v-icon>
                {{ $t('ButtonsLabel.Edit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-col>
    </v-row>
    <create-edit-user ref="CreateEditUserRef" @user-updated="bubbleEmit" />
    <change-password-dialog ref="ChangePasswordRef" />
  </v-container>
</template>

<script>
import CreateEditUser from '@/components/Dialogs/CreateEditUser.vue';
import apiService from '@/services/apiService';
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'UserDetails',
  components: { CreateEditUser, ChangePasswordDialog },
  data: () => ({
    user: null,
    isUpdating: false,
  }),
  computed: {
    ...mapGetters(['isAdmin']),
    roleString() {
      if (this.user.roles.length != 0) {
        return this.user.roles.join(' - ');
      } else {
        return '';
      }
    },
  },
  methods: {
    async getUser() {
      this.user = await apiService.getUserById(this.$route.params.Id);
    },
    async changeState() {
      this.isUpdating = true;
      await apiService.updateUserState(this.user.id);
      this.getUser();
      this.isUpdating = false;
    },
    async sendPasswordChangeEmail() {
      await apiService.resetPassword(this.user.email);
      this.$store.commit('alert', {
        show: true,
        title: 'Email sent',
        color: 'success',
        message: `Email password-reset request has been sent to ${this.user.email}`,
      });
    },
    EditUser() {
      this.$refs.CreateEditUserRef.editUser(this.user);
    },
    bubbleEmit() {
      this.getUser();
    },
  },
  created() {
    this.getUser();
  },
};
</script>